<template>
<div class="home">

    <v-container>
     <v-row id="opportunities" class="opportunities">
                  <v-col  cols="12" sm="12">
<h2>فرصت‌های شغلی
</h2>
<span class="hiring">در حال جذب</span>
                    </v-col>
<div v-if="$store.state.jobs.length > 0 && $store.state.departments.length > 0" class="tags">
<ul>
<li  :class="department == 'all'?'active':''" @click="get_jobs('all')">همه</li>
<template v-for="depart in $store.state.departments" >
  <template v-if="has_child(depart.id) == 'true'">




<li :class="depart.id == department?'active':''"  :key="depart.id"   @click="get_jobs(depart.id)">{{depart.title}} </li>
  </template>
</template>



</ul>
</div>

      <v-col v-if="$store.state.jobs.length > 0 && $store.state.departments.length > 0" cols="12" sm="12">
     <v-row class="jobs" transition="scroll-x-reverse-transition">

                  <v-col v-for="job in jobs" :key="job.id" cols="12" lg="4" sm="12" transition="scroll-x-reverse-transition">
  <router-link class="job-card" :to="'/position/'+job.slug">
  <span class="department"> {{job.department | depart($store.state.departments)}}</span>
<div class="content">
<h3>{{job.title}}</h3>

<ul  class="tags">


<template v-for="tag in job.tags" >
 <li  :key="tag">{{tag | tag($store.state.tags)}}</li>



</template>



</ul>
<button type="button" class="next"></button>
</div>
  
  </router-link>

                  </v-col>

                        <v-col  cols="12" sm="12">

       <a class="whatsapp web" href="https://web.whatsapp.com/send?phone=989020280335&text=" target="_blank">
       
        <img   :src="require('../assets/images/whatsapp.png')"  />
        ارسال اطلاعات در واتساپ
       </a>
       
       <a class="whatsapp mobile" href="https://api.whatsapp.com/send?phone=989020280335&text=" target="_blank">
       
           <img   :src="require('../assets/images/whatsapp.png')"  />
           ارسال اطلاعات در واتساپ
       </a>
  </v-col>

     </v-row>
      </v-col>

     </v-row>


                </v-container>


  <v-container>

         

                    <v-row>
                      <v-col id="intro" cols="12" sm="12">
                        <h1 class="title">
                          معرفی 
                          <span>نهال‌گشت</span>
                        </h1>
<span v-if="false" class="title-info">مقصد بعدی؟</span>

<p class="description">
ما در نهال‌گشت مفتخر به ارائه‌ی یک مشاوره سفرِ دقیق و بی‌نقص هستیم. در بازار به شدت رقابتی امروز که اولویت با جذب بی‌قید و شرط مشتری است، اطلاع‌رسانی و آگاهی‌بخشی صبورانه کمی غیرواقع‌بینانه به نظر می‌رسد؛ چرا که در صنعت پرابهام و توسعه نیافته گردشگری، هزینه جلب رضایت مسافر و برآورده کردن کامل انتظارات آن بسیار سرسام‌آور بوده و نیازمند صرف زمان و هزینه بالا می‌باشد.
<br>
رویکرد ما متفاوت است. ما هر نوع پکیج تور مسافرتی را برنامه‌ریزی نمی‌کنیم. در عوض، سعی ما همکاری با هتل‌ها و شرکت‌هایی است که بتوانند خدمتی در بالاترین کیفیت به مشتریان وفادار نهال‌گشت ارائه دهند. تیم پشتیبانی نهال‌گشت با گرفتن بازخورد روزانه از سفر مسافرین، وضعیت خدمت‌دهی هتل‌ها و چارترکننده‌ها را منصفانه بررسی می‌کند و با توجه به آن، تورهای پیشنهادی را طراحی و معرفی می‌نماید.
</p>
</v-col>
                      </v-row>
  </v-container>

  <div class="why-nahalgasht-parent">

      <span class="god">
اگر خدا بخواهد
        </span>

  <v-container>


      <v-row class="why-nahalgasht">
      
                  <v-col class="first" cols="12" sm="12" lg="3">
<h2>
  چرا
  <br>
<span>نهال‌گشت</span>
  ؟
</h2>

                    </v-col>
                     <v-col class="second" cols="12" sm="12" lg="3">
<p class="title-text">پرچم می‌کوبیم</p>
<p class="description">
تیم نهال‌گشت با در اختیار داشتن افرادی با سبک‌های فکری مختلف، در جستجوی رسیدن به تعالی و حرکت در مسیرِ مرز دانش و فناوری می‌باشد. ما با کمال‌گرایی به دنبال خلق ایده‌های نوآورانه و تولید محصول در سطح جهانی می‌باشیم. ما در تلاشیم تا با ارائه خدمتی خاص، تجربه‌ای به یاد ماندنی و خاطره‌انگیز برای مشتریان‌مان به ارمغان آوریم. 
</p>

                    </v-col>
                     <v-col class="third" cols="12" sm="12" lg="3">
<p class="title-text">  همه با هم</p>
<p class="description">
از جذاب‌ترین ارزش‌های سازمانی نهال‌گشت، تعهد تمامی کارکنان به رشد و پیشرفت مداوم می‌باشد. اهتمام منابع انسانی شرکت همواره بر ایجاد نقشه‌ی راهی برای رسیدن به ایده‌آل شغلی کارکنان بوده است.   مدیریت نیز، با اتخاذ سیاست‌های آگاهانه و با تأکید بر یادگیری در چشم‌انداز و استراتژی‌های سازمان، اشتیاق خود را نسبت به امر یادگیریِ پرسنل بارها اعلام نموده است. 
</p>

                    </v-col>
                     <v-col class="fourth" cols="12" sm="12" lg="3">
<p class="title-text">برای هم</p>
<p class="description">
کارکنان مجموعه در هر سطح، علاوه بر انجام شرح وظایف بایستی خود را ملزم به یادگیری منظم و مستمر بدانند. هر ساله بخش قابل توجهی از منابع نهال‌گشت، صرف آموزش، دانش و کسب مهارت‌های جدید می‌شود. در بازار جهانی امروز که بقای سازمان‌ها نیازمند نوآوری و انعطاف‌پذیری است، یادگیری مداوم و برگزاری جلسات طوفان فکری برای تولید انبوهی از ایده‌های خلاقانه، می‌تواند زمینه‌ساز حرکت در مسیر پیشرفت کارکنان شرکت گردد. 
</p>

                    </v-col>

                    </v-row>

                      </v-container>

  </div>
  <v-container>
     <v-row id="pictures" class="pictures" style="margin-bottom:200px">
                  <v-col  cols="12" sm="12">
<h2>تصاویر
<span>نهال‌گشت</span>
</h2>
<span class="title-info">عکس‌هایی از محیط شرکت</span>
                    </v-col>

 <v-col  cols="12" sm="12" lg="5">
     <v-img   :src="require('../assets/images/2.jpg')"  class="image-1"  cover  height="430" />
 </v-col>
 <v-col  cols="12" sm="12" lg="7">
 <v-img   :src="require('../assets/images/3.jpg')"  class="image-2"  cover  height="207" />
 <v-img   :src="require('../assets/images/4.jpg')"  class="image-3"  cover  height="207" />
 <v-img   :src="require('../assets/images/5.jpg')"  class="image-4"  cover  height="207" />

 </v-col>

     </v-row>

  </v-container>

            
</div>
</template>

<script>
  export default {
    name: 'HomeData',
    methods:{

  has_child: function (value) {

      var jobs =  this.$store.state.jobs.filter(function (e) {
           return e.department == value;
});

     if (jobs.length > 0){
return 'true'
    } else{
return 'false'
    }

  },

      get_jobs(type){
this.department = type
      }

    },

 mounted: function () {

  document.title = "استخدام در نهال گشت";

this.$ga.page({
  page: 'careers.nahalgasht.com',
  title: 'استخدام در نهال گشت',
  location: window.location.href
})


this.$store.state.job = false

//  let senddata = {
//       data: {
//         data: ['select', 'users', '*']
//       },
//       actions: ['get']
//     };
    
//  this.$http.post('http://localhost/function.php',{state:JSON.stringify( senddata )}).then((response) => {
//         console.log(response)
//       })


 


// var data = new FormData();
// data.append( "json", JSON.stringify( senddata ) );
// fetch("http://localhost/function.php",
// {
//     method: "POST",
//     body: JSON.stringify( senddata )
// })
// .then(function(res){ 
//   console.log(res)
//   return res.json(); })
// .then(function(data){ 
//   console.log(data)
//  })






 

  },
  computed: {
    // a computed getter
    jobs: function () {
      // `this` points to the vm instance
     const _this = this
      var jobs =  this.$store.state.jobs.filter(function (e) {
         if(_this.department == 'all'){
   return true;
      }else{
           return e.department == _this.department;
      }
});

return jobs
     
    }
  },
    data: () => ({
department : 'all'
    }),

      filters: {
  depart: function (value,departments) {
  if (!value) return ''
       var depart =  departments.filter(function (e) {
           return e.id == value;
});

return depart[0].title
  },
    tag: function (value,tags) {
  if (!value) return ''
       var depart =  tags.filter(function (e) {
           return e.id == value;
});

return depart[0].name
  }

  }

  }



</script>
