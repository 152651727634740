import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';
import VueAnalytics from 'vue-analytics'
Vue.use(Vuetify);


Vue.use(VueAnalytics, {
  id: 'UA-130893255-1'
})

Vue.use(Vuetify);

export default new Vuetify({
    rtl: true,
    theme: {
        themes: {
          light: {
            primary: '#be3190', // #E53935
          },
        },
      }
});
