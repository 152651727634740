<template>
  <HomeData />
</template>

<script>
  import HomeData from '../components/HomeData'

  export default {
    name: 'HomePage',

    components: {
      HomeData,
    },
  }
</script>
