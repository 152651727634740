<template>
<div class="header">
        
     <v-container>
    <v-row>
      <v-col class="d-none d-lg-block d-xl-block" cols="5" sm="5">
        


                    <nav>
                      <ul>
                        <li>
                          <span></span>
                          <span></span>
                          <span></span>
                          <template v-if="$store.state.job !== false">

  <a target="_blank" href="/#intro" >معرفی</a>
                          </template>
                              <template v-else>
 <a  href="#intro" >معرفی</a>
                          </template>
                        </li>
                        <li>
                            <span></span>
                          <span></span>
                          <span></span>

                                  <template v-if="$store.state.job !== false">

  <a target="_blank" href="/#pictures" >تصاویر</a>
                          </template>
                              <template v-else>
 <a  href="#pictures" >تصاویر</a>
                          </template>

                        </li>
                        <li>
                            <span></span>
                          <span></span>
                          <span></span>

                                  <template v-if="$store.state.job !== false">

  <a target="_blank" href="/#opportunities" >فرصت های شغلی</a>
                          </template>
                              <template v-else>
 <a  href="#opportunities" >فرصت های شغلی</a>
                          </template>

                
                        </li>
                      </ul>
                    </nav>
                      <!-- <router-link to="/">home</router-link>
<router-link to="/job">job</router-link> -->
                  </v-col>
      <v-col class="d-none d-lg-block d-xl-block py-0" cols="2" >
          <div class="logo">
  <router-link  :to="'/'">
    <v-img 
          :src="require('../assets/nahalgasht.png')"
          contain
          height="200"
        />
  </router-link>



          </div>
      
      </v-col>

         <v-col  cols="12" sm="12" md="5">
                
                 <div class="d-block d-sm-block d-md-none d-xl-none py-0 logo mobile" >
  <router-link  :to="'/'">
  <v-img 
          :src="require('../assets/nahalgasht.png')"
          contain
          height="200"
        />
          </router-link>
          </div>
          
                    <a class="nahalgasht" target="_blank" href="https://nahalgasht.com">وبسایت نهال‌گشت</a>
                    <ul class="social">
                      <li>
                        <a target="_blank" class="telegram" href="https://t.me/nahalgasht"></a>
                      </li>
                      <li>
                        <a target="_blank" class="instagaram" href="https://www.instagram.com/nahalgasht/"></a>
                      </li>
                      <li>
                        <a target="_blank" class="aparat" href="https://www.aparat.com/nahalgasht"></a>
                      </li>
                   
                    </ul>
                

                    
                  </v-col>

    </v-row>
      </v-container>
</div>
</template>

<script>
  export default {
    name: 'Header',

    data: () => ({
   
    }),

    
 mounted: function () {
  

 }
  }
</script>
